<template>
  <div>
    <div v-if="isLoading">
      Loading voucher detail
    </div>
    <div v-else>
      <div class="ps-breadcrumb">
        <div class="ps-container">
          <ul class="breadcrumb">
            <li><a href="">Home</a></li>
            <li>{{ voucher.name }}</li>
          </ul>
        </div>
      </div>
      <div class="ps-page--product">
        <div class="ps-container">
          <div class="ps-page__container">
            <div class="ps-page__left">
              <div class="ps-product--detail ps-product--fullwidth">
                <div class="ps-product__header">
                  <div class="ps-product__thumbnail" data-vertical="true">
                    <figure>
                      <div class="ps-wrapper">
                        <div class="ps-product__gallery" data-arrow="true">
                          <div class="item">
                            <img :src="`${voucher.image_path}`" :alt="voucher.name" />
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>
                  <div class="ps-product__info">
                    <h1>{{ voucher.name }}</h1>
                    <div class="ps-product__meta">
                    </div>
                    <div class="ps-product__desc">
                      <p v-if="voucher.merchant">
                        Sold By:<a :href="'/shop/' + voucher.merchant.slug"><strong> {{ voucher.merchant.name
                        }}</strong></a>
                      </p>
                      <p>
                        {{ voucher.description }}
                      </p>
                      <div class="form-group">
                        <label for="bundle">Select Amount</label>
                        <select class="form-control" id="bundle" v-model="form.voucherAmount">
                          <option v-for="voucherAmount in sortedAmounts" :key="voucherAmount.id" :value="voucherAmount">
                            {{ voucherAmount.amount }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="name">Full Name of Receiver</label>
                        <input type="text" class="form-control" id="name" v-model="form.full_name" />
                        <small v-if="errors && errors.full_name" class="full_name_error text-danger">
                          {{ }}
                          {{ errors.full_name }}
                        </small>
                      </div>
                      <div class="form-group">
                        <label for="phone">Phone Number of Receiver</label>
                        <input type="text" class="form-control" id="phone" placeholder="+263777123123"
                          v-model="form.mobile" />
                        <small v-if="errors && errors.mobile" class="phone_error text-danger">
                          {{ errors.mobile }}
                        </small>
                      </div>
                      <div class="form-group">
                        <label for="email">Email address of Receiver</label>
                        <small id="emailHelp" class="form-text text-muted">(Optional)</small>
                        <input type="email" class="form-control" id="email" aria-describedby="emailHelp"
                          v-model="form.email" />
                        <small v-if="errors && errors.email" class="phone_error text-danger">
                          {{ errors.email }}
                        </small>
                      </div>
                      <div class="form-group form-check">
                        <input required type="checkbox" class="form-check-input" id="agree_to_terms"
                          v-model="agreeToTerms" />

                        <label class="form-check-label" for="agree_to_terms">&nbsp; I have Read the Terms and
                          Agreements</label>
                        <small v-if="errors && errors.terms" class="phone_error text-danger">
                          {{ errors.terms }}
                        </small>
                      </div>
                      <div class="justify-content-between d-flex ps-product__shopping">
                        <!-- <div v-if="auth.isAuthenticated && cart.cartCount > 0">
                    <router-link :to="{ name: 'checkout' }" class="ps-btn btn-secondary">Checkout</router-link>
                  </div> -->
                        <div>
                          <button @click="addToCart()" class="btn btn-primary ls-submit-button">
                            <i v-if="!isLoading" class="icon-cart-add"></i>
                            Add to Cart
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ps-newsletter">
        <div class="container">
          <form class="ps-form--newsletter">
            <div class="row"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import AddVoucherAmountCart from "./AddVoucherAmountCart.vue";
export default {
  name: "VoucherDetail",
  components: { AddVoucherAmountCart },
  data() {
    return {
      voucher: {},
      vouchers: [],
      voucherData: {},
      isLoading: false,
      quantity: 1,
      form: {
        mobile: "",
        full_name: "",
        email: "",
        voucherAmount: "",
      },
      errors: {
        mobile: null,
        full_name: null,
        email: null,
        terms: false,
        voucherAmount: null
      },
      agreeToTerms: false
    };
  },
  watch: {
    $route(to, from) {
      this.getVouchers();
    },
  },
  mounted() {
    this.getVoucher();
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),

    sortedAmounts() {
      return this.voucher.amounts.sort((a, b) => a.amount - b.amount);
    }

  },
  methods: {

    getVoucher() {
      this.isLoading = true;
      this.$loading(true);
      axios.get(`vouchers/${this.$route.params.slug}/detail`).then((response) => {
        this.voucher = response.data;
        this.isLoading = false;
        this.$loading(false);
      });
    },

    addQuantity() {
      this.quantity += 1;
    },

    removeQuantity() {
      this.quantity -= 1;
    },

    isValid() {
      let isValid = true;
      if (this.form.voucherAmount === '') {
        this.errors['amount'] = 'Please select voucher amount'
        isValid = false;
      }
      if (this.form.mobile === '') {
        this.errors['mobile'] = 'This field is required'
        isValid = false;
      }
      if (this.form.full_name === '') {
        this.errors['full_name'] = 'This field is required'
        isValid = false;
      }
      if (!this.agreeToTerms) {
        this.errors['terms'] = 'Please agree to terms'
        isValid = false;
      }
      return isValid;
    },

    addToCart() {
      const voucherAmount = this.form.voucherAmount;
      if (this.isValid()) {
        const price = voucherAmount.amount;
        const quantity = 1;
        const total = price * quantity

        const product = {
          id: voucherAmount.id,
          name: `${this.voucher.name} - ${price}`,
          quantity: quantity,
          price: voucherAmount.amount,
          total: total,
          type: "voucher",
          shop: 2,
          props: { ...this.form, description: this.voucher.description }
        };

        this.$store.dispatch("cart/addToCart", {
          product: product,
          quantity: 1,
        });
        //this.$refs[`BuyVoucher${this.voucher.id}`].hide();
        this.$toasted.show("Voucher added to cart", {
          duration: 20000,
          action: [
            {
              text: "Checkout",
              push: {
                name: "cart",
              },
            },
            {
              text: "Continue shopping",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        });
      }
    },

    onCheckout() {
      this.$store.commit("auth/next", "checkout");
      this.$router.push({ name: "login" });
    }

  },
};
</script>
